<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-09-09 13:56:19
 * @LastEditors: gaolongzhi
 * @LastEditTime: 2020-09-09 14:05:45
-->
<template>
  <div class="articlePage">
    <!-- <header>电签协议</header> -->
    <p class="title">工蚁宝平台服务协议</p>
    <p>工蚁宝（以下简称“本平台”）是由深圳市前海工易科技有限公司（以下简称“本公司”）运营及维护的一家灵活用工平台。在开始签署协议前，请务必仔细阅读、理解并同意本协议的全部内容。一旦您开始签署协议，即表示您同意遵循本协议的所有约定。</p>
    <p>一、接受</p>
    <p>1、本协议已对您的权益有或可能具有重大关系的条款，及对本公司具有或可能具有免责或限制责任的条款予以标注。您同意，本公司有权对本协议内容进行单方面变更，并在本平台予以公布，无需另行单独通知您。</p>
    <p>2、您同意，接收本协议并使用平台服务时，您是具有法律规定的完全民事权利能力和民事行为能力，能独立承担民事责任的自然人。不具备前述条件的，您应停止使用本平台服务。</p>
    <p>3、您保证，在您同意本协议时，即表示您认可使用本平台作为缔约（包括但不限于电子协议）方式，认可您在本平台上的所有操作都是您个人意愿的表示，认可您再本平台上的电子签名和您的手写签名具备相同的效力。您和对方的电子缔约文件采用电子签名的方式（以下简称“电签”）进行签署，您知悉并承认，凡是在您账户下，数据电文或电子缔约文件签署各方已经完成电子签名的电子缔约文件，均具备法律效力。您将按照电子缔约文件内容，依法履行电子缔约文件相关，行使电子缔约文件相关权利。</p>
    <p>4、您知悉并同意本平台是提供电子缔约服务提供商，并不介入您和您的缔约对象之间的业务行为。其中涉及在线身份认证、协议签署、协议存证等由合作的第三方平台提供技术服务。</p>
    <p>5、开始签署协议前，您应当完成如下工作：了解并接收本协议的约束，了解并接受相关软件服务协议的约束；添加您本人的收款信息；完成个人在线身份认证。在完成上述流程后，您可以开始签署电子协议。电子协议签署完成后，将存储在本平台云端，您可以通过本平台查找、查阅协议。</p>
    <p>6、您知悉并同意本公司是第三方电子缔约服务提供商，并不介入任何您和您的缔约对象之间的业务行为。本公司不能控制缔约对象履行其在数据电文或电子缔约文件下的各项义务的能力。本公司不能也不会控制缔约各方履行协议义务。本公司对您与您的缔约对象之间就缔约的内容和缔约执行情况等产生的任何纠纷不承担任何法律责任或者连带法律责任。</p>
    <p>7、您知悉并同意本公司对本服务具备完全自主的知识产权，对工蚁宝平台或其相关内容采用任何复制或者反向工程手段皆是侵犯我公司权益的行为，我公司对此保留一切法律追诉的权利。</p>
    <p>8、您知悉并同意在使用本服务的过程中，本公司或者电子缔约关联方可能通过主动或者被动的方式获知相关信息。您同意本公司将按照公布的隐私政策声明进行处理。</p>
    <p>二、用户的权利和义务</p>
    <p>9、用户在使用工蚁宝平台提供的相关服务时必须保证遵守中国有关法律法规的规定，不得利用工蚁宝平台进行任何非法活动，遵守所有与使用工蚁宝平台有关的协议、规定、程序和惯例。</p>
    <p>10、用户需对其在工蚁宝平台上的所有行为及注册时向本公司提供的任何形式的材料、信息的合法性、真实性、准确性、完整性以及由此产生的所有问题承担责任。</p>
    <p>11、用户如因违反本协议规定的义务给本公司造成损失，必须负责相应赔偿。</p>
    <p>三、工蚁宝的权利和义务</p>
    <p>12、对于因不可抗力造成的服务中断或其他缺陷（包括但不限于自然灾害、社会事件以及因网站所具有的特殊性质而产生的包括黑客攻击、电信部门技术调整导致的影响、政府管制而造成的暂时性关闭在内的任何影响网络正常运营的因素），本公司不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。如因本公司原因，造成用户服务的不正常中断，用户有权向工蚁宝平台申请顺延被中断的服务时间。本公司不承担用户的其他任何损失。</p>
    <p>13、用户发生协议纠纷时，本公司将配合司法机关进行相关数据电文或电子缔约文件的鉴证鉴权。</p>
    <p>四、服务终止</p>
    <p>14、本公司有权在用户违反了本协议的任一条款的情形下拒绝用户的订购或终止、取消用户服务。</p>
    <p>15、用户就使用工蚁宝平台的第三方电子缔约服务与本公司签订了其他协议，若本协议内容与其他协议有冲突的，以其他协议内容为主。本协议任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</p>
    <p>五、争议的解决</p>
    <p>16、本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律，没有相关法律规定的，参照通用国际商业惯例和（或）行业惯例。因本协议产生之争议，应尽量友好协商解决。如协商不成，均应依照中华人民共和国法律予以处理。</p>
  </div>
</template>

<script>
export default {
  name: "help",
};
</script>

<style lang="less" scoped>
.articlePage {
  max-width: 900px;
  margin: 20px auto -320px;
  padding: 60px 100px;
  background: #fff;
  border-radius: 10px;
}
p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 12px;

  &.title {
    text-align: center;
    font-size: 18px;
    margin-bottom: 30px;
  }
}

img {
  display: block;
  width: 100%;
  height: auto;
  margin: 10px 0 15px;
}
</style>